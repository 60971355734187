// Set ENV defaults
const env = {
  SESSION_EXPIRY: 30,
  FEATURE_TOOLS: true,
  FEATURE_BLOG: true,
  FEATURE_FINANCIAL_PLANS: true,
  FEATURE_SUPPORT_URL: 'https://help.cidirectinvesting.com',
  WHITELABEL_BRAND: 'wealthbar',
  WHITELABEL_SHARE_INFO_WITH_ADVISOR: false,
  FLINKS_IFRAME_URL: 'https://wealthbar-dev-iframe.private.fin.ag/v2/?demo=true&consentEnable=true&accountSelectorEnable=true&accountSelectorMultiple=true&customerName=WealthBar',
};

const dataEl = document.getElementsByName('wealthbar-env')[0]
if (dataEl) {
  try {
    const data = dataEl.getAttribute('data');
    Object.assign(env, JSON.parse(data));
  } catch (error) {
    // continue regardless of error
  }
}

window.process = { env };

export default env;
